// import React from 'react';
// import { Menu } from 'antd';
// import { NavLink, useRouteMatch } from 'react-router-dom';
// import FeatherIcon from 'feather-icons-react';
// import propTypes from 'prop-types';
// import { NavTitle } from './style';

// const MenueItems = ({ darkMode, toggleCollapsed, topMenu }) => {
//   const { path } = useRouteMatch();
//   const pathName = window.location.pathname;
//   const pathArray = pathName.split(path);
//   const mainPath = pathArray[1];
//   const mainPathSplit = mainPath.split('/');
//   const [openKeys, setOpenKeys] = React.useState(
//     !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'fieldUser'}`] : [],
//   );

//   const userData = localStorage.getItem('user');
//   const user = JSON.parse(userData);

//   const onOpenChange = (keys) => {
//     setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
//   };

//   const onClick = (item) => {
//     if (item.keyPath.length === 1) setOpenKeys([]);
//   };

//   return (
//     <Menu
//       onOpenChange={onOpenChange}
//       onClick={onClick}
//       mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
//       theme={darkMode && 'dark'}
//       // // eslint-disable-next-line no-nested-ternary
//       defaultSelectedKeys={
//         !topMenu
//           ? [
//               `${
//                 mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
//               }`,
//             ]
//           : []
//       }
//       defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'fieldUser'}`] : []}
//       overflowedIndicator={<FeatherIcon icon="more-vertical" />}
//       openKeys={openKeys}
//     >
//       {user?.role !== 'teamSubManger' ? (
//         <>
//           {!topMenu && <NavTitle className="sidebar-nav-title">회원</NavTitle>}

//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/user`}>
//                   <FeatherIcon icon="smile" />
//                 </NavLink>
//               )
//             }
//             key="fieldUser"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/user`}>
//               회원관리
//             </NavLink>
//           </Menu.Item>
//         </>
//       ) : null}

//       {user?.role === 'admin' && (
//         <>
//           {!topMenu && <NavTitle className="sidebar-nav-title">파트너사관리</NavTitle>}
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/center`}>
//                   <FeatherIcon icon="map-pin" />
//                 </NavLink>
//               )
//             }
//             key="center"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/center`}>
//               <Text>
//               회사관리
//               </Text>
//             </NavLink>
//           </Menu.Item>
//         </>
//       )}

//       {user?.role !== 'teamSubManger' && user?.role !== 'manager' ? (
//         <>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/manager`}>
//                   <FeatherIcon icon="users" />
//                 </NavLink>
//               )
//             }
//             key="manager"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/manager`}>
//               매니저관리
//             </NavLink>
//           </Menu.Item>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/expert`}>
//                   <FeatherIcon icon="award" />
//                 </NavLink>
//               )
//             }
//             key="award"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/expert`}>
//               전문가관리
//             </NavLink>
//           </Menu.Item>
//         </>
//       ) : null}

//       {!topMenu && <NavTitle className="sidebar-nav-title">게시물 관리</NavTitle>}

//       {user?.role !== 'teamSubManger' ? (
//         <>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/report`}>
//                   <FeatherIcon icon="briefcase" />
//                 </NavLink>
//               )
//             }
//             key="report"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
//               리포트 관리
//             </NavLink>
//           </Menu.Item>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/video`}>
//                   <FeatherIcon icon="tv" />
//                 </NavLink>
//               )
//             }
//             key="video"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/video`}>
//               동영상 관리
//             </NavLink>
//           </Menu.Item>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/post`}>
//                   <FeatherIcon icon="file-text" />
//                 </NavLink>
//               )
//             }
//             key="post"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/post`}>
//               정보글 관리
//             </NavLink>
//           </Menu.Item>
//         </>
//       ) : null}

//       {!topMenu && <NavTitle className="sidebar-nav-title">정책 관리</NavTitle>}

//       {user?.role === 'master' && (
//         <>
//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/popup`}>
//                   <FeatherIcon icon="inbox" />
//                 </NavLink>
//               )
//             }
//             key="popup"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/popup`}>
//               팝업관리
//             </NavLink>
//           </Menu.Item>
//         </>
//       )}
//       {user?.role === 'master' && (
//         <Menu.Item
//           icon={
//             !topMenu && (
//               <NavLink className="menuItem-iocn" to={`${path}/notice`}>
//                 <FeatherIcon icon="alert-circle" />
//               </NavLink>
//             )
//           }
//           key="notice"
//         >
//           <NavLink onClick={toggleCollapsed} to={`${path}/notice`}>
//             공지관리
//           </NavLink>
//         </Menu.Item>
//       )}
//       <Menu.Item
//         icon={
//           !topMenu && (
//             <NavLink className="menuItem-iocn" to={`${path}/messaging`}>
//               <FeatherIcon icon="bell" />
//             </NavLink>
//           )
//         }
//         key="push"
//       >
//         <NavLink onClick={toggleCollapsed} to={`${path}/messaging`}>
//           알림메세지
//         </NavLink>
//       </Menu.Item>

//       {user?.role === 'master' && (
//         <Menu.Item
//           icon={
//             !topMenu && (
//               <NavLink className="menuItem-iocn" to={`${path}/term`}>
//                 <FeatherIcon icon="pocket" />
//               </NavLink>
//             )
//           }
//           key="term"
//         >
//           <NavLink onClick={toggleCollapsed} to={`${path}/term`}>
//             약관관리
//           </NavLink>
//         </Menu.Item>
//       )}

//       {user?.role === 'master' && (
//         <>
//           {!topMenu && <NavTitle className="sidebar-nav-title">앱관리</NavTitle>}

//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/setting`}>
//                   <FeatherIcon icon="tool" />
//                 </NavLink>
//               )
//             }
//             key="setting"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
//               검색기 설정
//             </NavLink>
//           </Menu.Item>
//           {!topMenu && <NavTitle className="sidebar-nav-title">관리자</NavTitle>}

//           <Menu.Item
//             icon={
//               !topMenu && (
//                 <NavLink className="menuItem-iocn" to={`${path}/admin`}>
//                   <FeatherIcon icon="shield" />
//                 </NavLink>
//               )
//             }
//             key="admin"
//           >
//             <NavLink onClick={toggleCollapsed} to={`${path}/admin`}>
//               관리자 설정
//             </NavLink>
//           </Menu.Item>
//         </>
//       )}
//     </Menu>
//   );
// };

// MenueItems.propTypes = {
//   darkMode: propTypes.bool,
//   topMenu: propTypes.bool,
//   toggleCollapsed: propTypes.func,
// };

// export default MenueItems;
import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const MenuItems = () => {
  const { url } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(url);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [collapsed, setCollapsed] = useState(false);

  const [openKeys, setOpenKeys] = useState([`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'fieldUser'}`]);

  const userData = localStorage.getItem('user');
  const user = JSON.parse(userData);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    user?.userType !== 'expert' && {
      key: 'sub1',
      label: '회원',
      type: 'group',
      children: [
        {
          key: 'user',
          label: <NavLink to={`${url}/user`}>회원관리</NavLink>,
          icon: <FeatherIcon icon="user" />,
        },
      ],
    },
    user?.role === 'master' && {
      key: 'sub2',
      label: '파트너사',
      type: 'group',
      children: [
        {
          key: 'center',
          label: <NavLink to={`${url}/center`}>회사관리</NavLink>,
          icon: <FeatherIcon icon="map-pin" />,
        },
        {
          key: 'manager',
          label: <NavLink to={`${url}/manager`}>매니저관리</NavLink>,
          icon: <FeatherIcon icon="users" />,
        },
        {
          key: 'expert',
          label: <NavLink to={`${url}/expert`}>전문가관리</NavLink>,
          icon: <FeatherIcon icon="award" />,
        },
      ],
    },
    {
      key: 'sub3',
      label: '게시물',
      type: 'group',
      children: [
        {
          key: 'report',
          label: <NavLink to={`${url}/report`}>리포트 관리</NavLink>,
          icon: <FeatherIcon icon="briefcase" />,
        },
        {
          key: 'video',
          label: <NavLink to={`${url}/video`}>동영상 관리</NavLink>,
          icon: <FeatherIcon icon="tv" />,
        },
        user?.userType !== 'expert' && {
          key: 'post',
          label: <NavLink to={`${url}/post`}>정보글 관리</NavLink>,
          icon: <FeatherIcon icon="file-text" />,
        },
        user?.userType !== 'expert' && {
          key: 'pick',
          label: <NavLink to={`${url}/pick`}>반짝주 관리</NavLink>,
          icon: <FeatherIcon icon="star" />,
        },
      ],
    },
    user?.userType !== 'expert' && {
      key: 'sub4',
      label: '알림',
      type: 'group',
      children: [
        {
          key: 'messaging',
          label: <NavLink to={`${url}/messaging`}>알림메세지</NavLink>,
          icon: <FeatherIcon icon="bell" />,
        },
        {
          key: 'group',
          label: <NavLink to={`${url}/group`}>알림그룹관리</NavLink>,
          icon: <FeatherIcon icon="folder" />,
        },
      ],
    },
    user?.role === 'master' && {
      key: 'sub5',
      label: '앱관리',
      type: 'group',
      children: [
        {
          key: 'popup',
          label: <NavLink to={`${url}/popup`}>팝업관리</NavLink>,
          icon: <FeatherIcon icon="inbox" />,
        },
        {
          key: 'notice',
          label: <NavLink to={`${url}/notice`}>공지관리</NavLink>,
          icon: <FeatherIcon icon="alert-circle" />,
        },
        {
          key: 'term',
          label: <NavLink to={`${url}/term`}>약관관리</NavLink>,
          icon: <FeatherIcon icon="pocket" />,
        },
        {
          key: 'setting',
          label: <NavLink to={`${url}/setting`}>검색기 설정</NavLink>,
          icon: <FeatherIcon icon="tool" />,
        },
        {
          key: 'master',
          label: <NavLink to={`${url}/admin`}>관리자 설정</NavLink>,
          icon: <FeatherIcon icon="shield" />,
        },
      ],
    },
  ].filter(Boolean); // Filter out any null values

  return (
    <Menu
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode="inline"
      theme="light"
      inlineCollapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      items={items}
      onOpenChange={onOpenChange}
      onClick={onClick}
      openKeys={openKeys}
    />
  );
};

export default MenuItems;
