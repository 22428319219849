import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Messagings = lazy(() => import('../../container/messaging'));
const EditMission = lazy(() => import('../../container/messaging/EditMission'));
const ViewMission = lazy(() => import('../../container/messaging/ViewMission'));
const CreateMission = lazy(() => import('../../container/messaging/CreateMission'));
const EditGroup = lazy(() => import('../../container/messaging/EditGroup'));

function Messaging() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/messaging`} component={Messagings} />
      <Route path={`${path}/edit-messaging/:id`} component={EditMission} />
      <Route path={`${path}/create-messaging/`} component={CreateMission} />
      <Route path={`${path}/view-messaging/:id`} component={ViewMission} />
      <Route path={`${path}/group-messaging`} component={EditGroup} />
    </Switch>
  );
}

export default Messaging;
