import React from 'react';
import Cookies from 'js-cookie';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import { authService } from '../../../Firebase';

function AuthInfo() {
  const dispatch = useDispatch();
  const history = useHistory();

  // const [state, setState] = useState({
  //   flag: 'english',
  // });
  // const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    authService.signOut();
    dispatch(logOut());
    localStorage.removeItem('user');
    Cookies.remove('logedIn');

    history.push('/');
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> 로그아웃
        </Link>
      </div>
    </UserDropDwon>
  );

  // const onFlagChangeHandle = (value) => {
  //   setState({
  //     ...state,
  //     flag: value,
  //   });
  // };

  // const country = (
  //   <NavAuth>
  //     <Link onClick={() => onFlagChangeHandle('english')} to="#">
  //       <img src={require('../../../static/img/flag/english.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('germany')} to="#">
  //       <img src={require('../../../static/img/flag/germany.png')} alt="" />
  //       <span>Germany</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('spain')} to="#">
  //       <img src={require('../../../static/img/flag/spain.png')} alt="" />
  //       <span>Spain</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('turky')} to="#">
  //       <img src={require('../../../static/img/flag/turky.png')} alt="" />
  //       <span>Turky</span>
  //     </Link>
  //   </NavAuth>
  // );

  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://firebasestorage.googleapis.com/v0/b/supercare-a7fda.appspot.com/o/Images%2Fblank_profile.png?alt=media&token=a8e4ddbd-4c5b-46be-9cee-1199156f07f7" />
            <span style={{ fontSize: 12, textAlign: 'center' }}>{user?.name || '관리자'}</span>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
