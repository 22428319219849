import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Mission = lazy(() => import('../../container/mission'));
const EditMission = lazy(() => import('../../container/mission/EditMission'));
const ViewMission = lazy(() => import('../../container/mission/ViewMission'));
const CreateMission = lazy(() => import('../../container/mission/CreateMission'));

function Posts() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/post`} component={Mission} />
      <Route path={`${path}/edit-post/:id`} component={EditMission} />
      <Route path={`${path}/create-post/`} component={CreateMission} />
      <Route path={`${path}/view-post/:id`} component={ViewMission} />
    </Switch>
  );
}

export default Posts;
