import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Groups = lazy(() => import('../../container/group'));
const EditGroup = lazy(() => import('../../container/group/EditGroup'));
const CreateGroup = lazy(() => import('../../container/group/CreateGroup'));

function Group() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/group`} component={Groups} />
      <Route path={`${path}/edit-group/:id`} component={EditGroup} />
      <Route path={`${path}/create-group/`} component={CreateGroup} />
      <Route path={`${path}/group-group`} component={EditGroup} />
    </Switch>
  );
}

export default Group;
