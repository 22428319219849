import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Notice = lazy(() => import('../../container/notice'));
const EditNotice = lazy(() => import('../../container/notice/EditNotice'));
const ViewNotice = lazy(() => import('../../container/notice/ViewNotice'));
const CreateNotice = lazy(() => import('../../container/notice/CreateNotice'));

function Notices() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/notice`} component={Notice} />
      <Route path={`${path}/edit-Notice/:id`} component={EditNotice} />
      <Route path={`${path}/create-Notice/`} component={CreateNotice} />
      <Route path={`${path}/view-Notice/:id`} component={ViewNotice} />
    </Switch>
  );
}

export default Notices;
