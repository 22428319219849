import React from 'react';
import { Main } from '../../styled';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Main style={{ flex: 1, height: '100vh' }}>
        <WraperContent />
      </Main>
    );
  };
};

export default AuthLayout;
