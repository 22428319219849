import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyChBtmyzToh7boZY26byNxaxBOJyKile-k',
  authDomain: 'cham-investment.firebaseapp.com',
  projectId: 'cham-investment',
  storageBucket: 'cham-investment.appspot.com',
  messagingSenderId: '1080418872675',
  appId: '1:1080418872675:web:34de8aa1e65b3b475e3756',
  measurementId: 'G-MW11QL3N7Q',
};

const app = initializeApp(firebaseConfig);
export default app;
export const authService = getAuth(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const storage = getStorage(app);
