import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Mission = lazy(() => import('../../container/video'));
const EditMission = lazy(() => import('../../container/video/EditMission'));
const ViewMission = lazy(() => import('../../container/video/ViewMission'));
const CreateMission = lazy(() => import('../../container/video/CreateMission'));

function Videos() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/video`} component={Mission} />
      <Route path={`${path}/edit-video/:id`} component={EditMission} />
      <Route path={`${path}/create-video/`} component={CreateMission} />
      <Route path={`${path}/view-video/:id`} component={ViewMission} />
    </Switch>
  );
}

export default Videos;
