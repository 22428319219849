import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Center = lazy(() => import('../../container/center'));
const EditCenter = lazy(() => import('../../container/center/EditCenter'));
const ViewCenter = lazy(() => import('../../container/center/ViewCenter'));
const CreateCenter = lazy(() => import('../../container/center/CreateCenter'));

function Centers() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/center`} component={Center} />
      <Route path={`${path}/edit-center/:id`} component={EditCenter} />
      <Route path={`${path}/create-center/`} component={CreateCenter} />
      <Route path={`${path}/view-center/:id`} component={ViewCenter} />
    </Switch>
  );
}

export default Centers;
