import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Center = lazy(() => import('../../container/manager'));
const EditCenter = lazy(() => import('../../container/manager/EditCenter'));
const ViewCenter = lazy(() => import('../../container/manager/ViewCenter'));
const CreateCenter = lazy(() => import('../../container/manager/CreateCenter'));
const UpdateManager = lazy(() => import('../../container/manager/UpdateManager'));

function Managers() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/manager`} component={Center} />
      <Route path={`${path}/edit-manager/:id`} component={EditCenter} />
      <Route path={`${path}/create-manager/`} component={CreateCenter} />
      <Route path={`${path}/view-manager/:id`} component={ViewCenter} />
      <Route path={`${path}/update-manager`} component={UpdateManager} />
    </Switch>
  );
}

export default Managers;
