import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import User from './user';
import Messaging from './messaging';
import Centers from './center';
import Notices from './notice';
import Managers from './manager';
import Posts from './post';
import Experts from './expert';
import Videos from './video';
import Reports from './report';
import Group from './group';
import Pick from './pick';

import withAdminLayout from '../../layout/withAdminLayout';

const Setting = lazy(() => import('../../container/settings/Settings'));
const Policy = lazy(() => import('../../container/policy/Policy'));
const Term = lazy(() => import('../../container/term/Terms'));
const AdminUser = lazy(() => import('../../container/adminUsers/AdminUsers'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}`} component={User} />
        <Route path={`${path}`} component={Messaging} />
        <Route path={`${path}`} component={Managers} />
        <Route path={`${path}`} component={Experts} />
        <Route path={`${path}`} component={Videos} />
        <Route path={`${path}`} component={Reports} />
        <Route path={`${path}/admin`} component={AdminUser} />
        <Route path={`${path}`} component={Posts} />
        <Route path={`${path}/popup`} component={Policy} />
        <Route path={`${path}/term`} component={Term} />
        <Route path={`${path}`} component={Group} />
        <Route path={`${path}`} component={Centers} />
        <Route path={`${path}`} component={Notices} />
        <Route path={`${path}`} component={Pick} />
        <Route path={`${path}/setting`} component={Setting} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
