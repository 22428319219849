import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Pick = lazy(() => import('../../container/picks'));
const CreatePick = lazy(() => import('../../container/picks/CreatePick'));

function Picks() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/pick`} component={Pick} />
      <Route path={`${path}/create-pick/`} component={CreatePick} />
    </Switch>
  );
}

export default Picks;
