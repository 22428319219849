import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Users = lazy(() => import('../../container/users'));
const EditUser = lazy(() => import('../../container/users/EditUser'));
const ViewUser = lazy(() => import('../../container/users/ViewUser'));
const CreateUser = lazy(() => import('../../container/users/CreateUser'));
const UpdateUser = lazy(() => import('../../container/users/UpdateUser'));

function User() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/user`} component={Users} />
      <Route path={`${path}/create-user`} component={CreateUser} />
      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />
      <Route path={`${path}/update-user`} component={UpdateUser} />
    </Switch>
  );
}

export default User;
