import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Mission = lazy(() => import('../../container/report'));
const EditMission = lazy(() => import('../../container/report/EditMission'));
const ViewMission = lazy(() => import('../../container/report/ViewMission'));
const CreateMission = lazy(() => import('../../container/report/CreateMission'));

function Reports() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/report`} component={Mission} />
      <Route path={`${path}/edit-report/:id`} component={EditMission} />
      <Route path={`${path}/create-report/`} component={CreateMission} />
      <Route path={`${path}/view-report/:id`} component={ViewMission} />
    </Switch>
  );
}

export default Reports;
