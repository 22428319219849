import actions from './actions';

const { logoutBegin, logoutSuccess, logoutErr } = actions;

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      localStorage.removeItem('user');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { logOut };
